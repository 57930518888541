import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../config.service';
import { SeoService } from '../seo.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  our_apps: any;
  home: any = [];
  featured_apps: any = [];

  constructor(private config: ConfigService, private seo: SeoService, private router: Router) { }

  ngOnInit() {
    this.our_apps = this.getOurApps();
    this.home = this.getHome();
    this.featured_apps = this.our_apps.filter((app) => app.featured == true);
    this.seo.generateTags({
      keywords: 'ezpuppies, indie dev, mobile games, mobile apps',
      title: 'Home Page',
      description: '"EzPuppies is an independent games and applications development studio. We created excellent Andriod and Ios mobile apps for both Google Play and App Store.',
      image: 'assets/images/eplogo.jpg',
    });
  }


  navigateToAppDetails(data) {
    this.router.navigate(['/app-details/'], {
      queryParams: { appID: data.appID }
    });
  }

  navigateToOurApp() {
    this.router.navigate(['/our-apps/']);
  }

  getOurApps() {
    return this.config.getConfig().our_apps;
  }

  getHome() {
    return this.config.getConfig().home;
  }


  goToLink(url: string) {
    window.open(url, "_blank");
  }


  clampInteger(num, min, max) {
    return num <= min ? min : num >= max ? max : num;
  }
}


