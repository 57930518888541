import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../config.service';

@Component({
  selector: 'app-social',
  templateUrl: './social.component.html',
  styleUrls: ['./social.component.css']
})
export class SocialComponent implements OnInit {

  socials = {};

  constructor(private config: ConfigService) { }

  ngOnInit() {
    this.socials = this.getSocials();
  }

  getSocials() {
    return this.config.getConfig().home.find_us;
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }


}
