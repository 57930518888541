
export const configuration = {

  home: {
    our_team: [
      {
        name: 'Mumu',
        image: 'assets/images/team/mumu2.png',
        jobScope: ['Programmer', 'Web Developer'],
      },
      {
        name: 'Railious',
        image: 'assets/images/team/railious2.png',
        jobScope: ['UI/UX Designer', 'Animator', 'Artist'],
      },
    ],
    find_us: [
      {
        media: 'Facebook',
        fa_icon: 'facebook',
        icon: 'assets/images/facebook.png',
        url: 'https://fb.me/ezpuppies',
      },
      {
        media: 'Youtube',
        fa_icon: 'youtube',
        icon: 'assets/images/youtube.png',
        url: 'https://www.youtube.com/channel/UCGOjSayET0KpE-hiKtvb_gw',
      },
    ],
  },
  our_apps:
    [
      {
        keywords: 'ezpuppies ,moneylah, money manager, budgeting app, budgeting, budget',
        title: 'MoneyLah - Budget & Expense',
        appID: 'moneylah',
        category: 'Applications',
        featured: true,
        description: 'Discover a smarter way to manage your finances with MoneyLah, your all-in-one budget and expense tracking app. Take control of your money and achieve your financial goals with these powerful features:',
        icon: 'assets/images/app/money-lah/app_icon.png',
        hasVideo: false,
        videoUrl: '',
        hasPlayStore: true,
        playStoreUrl: 'https://play.google.com/store/apps/details?id=com.ezpuppies.moneylah',
        hasAppStore: true,
        appStoreUrl: 'https://apps.apple.com/app/id6466561147',
        features: [
          'Track Your Spending with Categories and Subcategories - Categorize your expenses and income with ease. Gain insight into where your money is going, and make informed decisions to save and spend wisely',
          'Stay on Top of Your Finances with Budgets - Set up budgets and effortlessly monitor your spending. MoneyLah helps you stick to your financial plan, ensuring you never overspend',
          'Plan Ahead with the Interactive Calendar - Our calendar view makes it simple to visualize your financial future.See your income and expenses at a glance, so you can plan ahead and avoid surprises',
          'Recurring Transactions - Effortlessly manage regular expense and income with our recurring transactions feature',
          'Manage Multiple Wallets - Keep all your financial accounts in one place. With support for multiple currencies, MoneyLah makes it easy to manage your accounts, whether you\'re at home or abroad',
          'Gain Valuable Insights with In-Depth Analysis - Understand your financial trends and make data-driven decisions. Our analysis tools provide actionable insights to improve your financial health',
          'Enhance Security with Screen Lock - Protect your financial data with an optional screen lock. Keep your information safe and accessible only to you',
          'Handle Multiple Currencies Effortlessly - Whether you travel often or have international financial commitments, MoneyLah supports multiple currencies for easy and accurate tracking',
          'Organize Your Finances with Multiple Accounts - Manage various financial aspects of your life by creating multiple accounts within the app',
          'Never Lose Your Data with Backup and Restore - Worry less about losing your financial data. MoneyLah offers convenient backup and restore options to keep your information secure',
          'Stay Informed with Notifications - Set up notifications for your budgets, transactions, and more. MoneyLah ensures you stay in the loop, even when you\'re on the go',
          'Tailor Your Experience with Preferences - Customize MoneyLah to suit your financial management preferences. Make it uniquely yours',
        ],
        screenshots: [
          'assets/images/app/money-lah/screenshot1.png',
          'assets/images/app/money-lah/screenshot3.png',
          'assets/images/app/money-lah/screenshot2.png',
          'assets/images/app/money-lah/screenshot4.png',
          'assets/images/app/money-lah/screenshot5.png',
          'assets/images/app/money-lah/screenshot6.png',
          'assets/images/app/money-lah/screenshot7.png',
          'assets/images/app/money-lah/screenshot8.png',
        ],
      },
      {
        keywords: 'ezpuppies ,ezdebt book, debt management, debt book',
        title: 'EzDebt Book',
        appID: 'ezdebt_book',
        category: 'Applications',
        featured: true,
        description: 'EzDebt Book provides an offline solution for you to manage, check and remind your daily debts. Also, we provide cloud syncing feature to secure your data.',
        icon: 'assets/images/app/ezdebt-book/app_icon.png',
        hasVideo: true,
        videoUrl: 'https://www.youtube.com/embed/l2A9pqzFk28',
        hasPlayStore: true,
        playStoreUrl: 'https://play.google.com/store/apps/details?id=com.ezpuppies.ezdebt_book',
        hasAppStore: true,
        appStoreUrl: 'https://apps.apple.com/app/id1526283952',
        features: [
          '🖌 Record with Attachments – Record your loan or debt by a designed form that support to upload multiple attachments.',
          '🧑‍ Profile – Add debtor or loaner from your contact list or fill a designed form.',
          '📞 Call & SMS – Call/SMS or navigate to your debtor or loaner easily.',
          '💬 Preferences – Select your preferred terms that fit you the best.',
          '☁️ Cloud Syncing – Never loss your data by syncing your data to your own private personal Google Drive.',
          '🌐 Localization – You can change your preferred language anytime in the app.',
          '🔒 Security Protection – Secure and protect your private diaries entries with Fingerprint and 6 digits of Passcode Lock',
          '🔔 Notification – Freely to set notification to specific profile by specific date and time, daily and weekly notification.',
        ],
        screenshots: [
          'assets/images/app/ezdebt-book/screenshot1.png',
          'assets/images/app/ezdebt-book/screenshot3.png',
          'assets/images/app/ezdebt-book/screenshot2.png',
          'assets/images/app/ezdebt-book/screenshot4.png',
          'assets/images/app/ezdebt-book/screenshot5.png',
          'assets/images/app/ezdebt-book/screenshot6.png',
          'assets/images/app/ezdebt-book/screenshot7.png',
          'assets/images/app/ezdebt-book/screenshot8.png',

        ],
      },

    ],
  footer: {
    copyrighttext: 'Copyright © 2020',
    developer: 'EzPuppies All Rights Reserved.',
    developerlink: '',
  },
};
