import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../config.service';
import { SeoService } from '../seo.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-our-apps',
  templateUrl: './our-apps.component.html',
  styleUrls: ['./our-apps.component.css']
})
export class OurAppsComponent implements OnInit {
  our_apps: any;
  applications: any = [];
  games: any = [];

  constructor(private config: ConfigService, private seo: SeoService, private router: Router) { }


  ngOnInit() {
    this.our_apps = this.getOurApps();
    this.seo.generateTags({
      keywords: 'ezpuppies, indie dev, mobile games, mobile apps',
      title: 'Our Apps',
      description: '"Check out Games and Apps from Ez Puppies Team.',
      image: 'assets/images/eplogo.jpg',
    });
    this.applications = this.our_apps.filter((app) => app.category == "Applications");
    this.games = this.our_apps.filter((app) => app.category == "Games");
  }

  getOurApps() {
    return this.config.getConfig().our_apps;

  }

  navigateToAppDetails(data) {
    this.router.navigate(['/app-details/'], {
      queryParams: { appID: data.appID }
    });
  }

  clampInteger(num, min, max) {
    return num <= min ? min : num >= max ? max : num;
  }

}
