import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../config.service';
import { SeoService } from '../seo.service';


@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {


  constructor(private config: ConfigService, private seo: SeoService,) { }

  ngOnInit() {
    this.getServices();
    this.seo.generateTags({
      keywords: 'ezpuppies, indie dev, mobile games, mobile apps',
      title: 'Privacy & Policy',
      description: "This Privacy Policy applies to all Ez Puppies's applications, games, websites and related services.",
      image: 'assets/images/eplogo.jpg',
    });
  }

  getServices() {
    return this.config.getConfig();
  }
}
