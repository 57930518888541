import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../config.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {

  menu: any;
  menuOpen: boolean;
  database = 'menu';

  constructor(
    private config: ConfigService,
    private router: Router
  ) { }

  ngOnInit() {

    this.menuOpen = false;
    this.getMenu();

  }

  toggleMenu(status: boolean) {
    this.menuOpen = status;
  }

  getMenu() {
    this.config.getsettings(this.database).subscribe(

      setting => {
        this.menu = setting;
      }
    );
  }

  navigateToHome() {
    this.router.navigate(['/home']);
  }
}
