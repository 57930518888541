import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { NotfoundComponent } from './notfound/notfound.component';
import { RoutegaurdService } from './routegaurd.service';
import { OurAppsComponent } from './our-apps/our-apps.component';
import { AppDetailsComponent } from './app-details/app-details.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { HomeComponent } from './home/home.component';


const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent, data: { title: 'Home' } },
  { path: 'our-apps', component: OurAppsComponent, data: { title: 'Our Apps' } },
  { path: 'app-details', component: AppDetailsComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent, data: { title: 'Privacy Policy' } },
  { path: 'terms-of-use', component: TermsOfUseComponent, data: { title: 'Terms Of Use' } },
  { path: '404', component: NotfoundComponent },
  { path: '**', redirectTo: '/404', data: { title: 'Page Not Found' } },
];


@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  declarations: [],
  exports: [RouterModule]
})
export class AppRoutingModule { }
