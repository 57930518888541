import { Injectable } from '@angular/core';
import { configuration } from './configuration';
import { Observable, of } from 'rxjs';
import { Post } from './post';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  config = configuration;
  apiUrl = 'api/posts';

  constructor(private http: HttpClient) { }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  getConfig() {
    return this.config;
  }

  getPosts(): Observable<Post[]> {
    return this.http.get<any>(this.apiUrl).pipe(
      tap(
        post => console.log(post)
      ),
      catchError(this.handleError('Get Posts', []))
    );
  }

  getsettings(database: string, id?: any): Observable<any[]> {
    let uid = id || null;
    let url: string;
    if (uid !== null) {
      url = `api/${database}/${id}`;
    } else {
      url = `api/${database}`;
    }

    return this.http.get<any>(url).pipe(
      tap(
        setting => { }
      ),
      catchError(this.handleError(` get for ${database}`, []))
    );
  }


}
