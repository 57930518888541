import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(private meta: Meta) { }

  generateTags(config) {

    config = {
      keywords: 'ezpuppies, indie dev, mobile games, mobile apps',
      title: 'Home Page',
      description: '"EzPuppies is an independent games and applications development studio. We created excellent Andriod and Ios mobile apps for both Google Play and App Store.',
      image: 'assets/images/eplogo.jpg',
      ...config
    }

    this.meta.updateTag({ name: 'keywords', content: config.keywords });
    this.meta.updateTag({ name: 'description', content: config.description });

    this.meta.updateTag({ property: 'og:title', content: config.title });
    this.meta.updateTag({ property: 'og:description', content: config.description });
    this.meta.updateTag({ property: 'og:image', content: config.image });

    this.meta.updateTag({ name: 'twitter:title', content: config.title });
    this.meta.updateTag({ name: 'twitter:descriptio', content: config.description });
    this.meta.updateTag({ name: 'twitter:image', content: config.image });
  }
}
