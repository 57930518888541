import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../config.service';
import { SeoService } from '../seo.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-app-details',
  templateUrl: './app-details.component.html',
  styleUrls: ['./app-details.component.css']
})
export class AppDetailsComponent implements OnInit {


  constructor(
    private titleService: Title,
    private config: ConfigService,
    private seo: SeoService,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer) { }
  safe_url: any;
  our_apps: any;
  app_details: any;
  sub: any;

  ngOnInit() {
    this.our_apps = this.getOurApps();
    this.sub = this.route
      .queryParams
      .subscribe(data => {
        this.app_details = this.our_apps.find(app => app.appID == data.appID);

      });
    this.seo.generateTags({
      keywords: this.app_details.keywords,
      title: this.app_details.title,
      description: this.app_details.description,
      image: this.app_details.icon,
    });
    this.titleService.setTitle('EzPuppies - ' + this.app_details.title);
    this.safe_url = this.sanitizer.bypassSecurityTrustResourceUrl(this.app_details.videoUrl);


  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getOurApps() {
    return this.config.getConfig().our_apps;
  }

  navigateToOurApps() {
    this.router.navigate(['/our-apps/']);
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }

}
